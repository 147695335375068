import { format } from 'date-fns';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import Terminal, { ColorMode, LineType } from 'react-terminal-ui';

import styled from 'styled-components';

const Container = styled.div`
  padding: 40px;
  background-color: #252a33;
  height: 100vh;
  div {
    background-color: transparent;
  }

  .react-terminal-line {
    white-space: pre-wrap !important;
  }

  @media (max-width: 800px) {
    padding: 0;

    .react-terminal-wrapper:after {
      left: 90px;
      top: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
    }

    .react-terminal-wrapper {
      padding: 45px 10px;
      overflow-x: hidden;
    }
  }
`;

type TerminalLineDataType = {
  type: LineType;
  value: string;
};

type InformationItemType = {
  title: string;
  where: string;
  from: string;
  to: string;
  description?: string;
};

const TerminalApp = () => {
  const [terminalLineData, setTerminalLineData] = useState<
    TerminalLineDataType[]
  >([
    {
      type: LineType.Output,
      value: 'Bem vindo ao vagbash 1.0, o Terminal do Vagner!',
    },
    { type: LineType.Output, value: '' },
    {
      type: LineType.Output,
      value: '* LinkedIn : https://linkedin.com/in/vagnerleitte',
    },
    {
      type: LineType.Output,
      value: '* Github : https://github.com/vagnerleitte',
    },
    { type: LineType.Output, value: '* E-mail : vagner.leite.silva@gmail.com' },
    { type: LineType.Output, value: '' },
    {
      type: LineType.Output,
      value: `Informações do sistema em ${format(
        new Date(),
        'dd-MM-yyyy HH:mm:ss',
      )}`,
    },
    {
      type: LineType.Output,
      value: "Digite 'help' se não souber o que está acontecendo.",
    },
  ]);

  const defaultResponse = (input: string) =>
    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: `comando "${input}" não reconhecido` },
      { type: LineType.Output, value: '' },
    ]);

  const contact = (input: string) =>
    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: 'vagner.leite.silva@gmail.com' },
      { type: LineType.Output, value: '' },
    ]);

  const salary = (input: string) =>
    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      {
        type: LineType.Output,
        value: 'Podemos discutir isso de acordo com o job description.',
      },
      { type: LineType.Output, value: '' },
    ]);

  const experiences = (input: string) => {
    const myExperiences = [
      {
        title: 'Frontend Engineer',
        where: 'Desenvolvedor de front-end',
        from: '3/2023',
        to: 'atual',
        description: '',
      },
      {
        title: 'Frontend Engineer',
        where: 'Sólides Tecnologia',
        from: '5/2022',
        to: '1/2023',
        description:
          'Criamos na Sólides uma aplicação em react baseada em micro frontend utilizando a lib Single-SPA para orquestrar as micro aplicações. Também fizemos uma frente para padronização da identidade visual dos produtos da empresa, o que resultou na identificação e correção de bugs, melhorias de performance, padronização e redefinição de padrões de desenvolvimento e diminuição do tamanho em kb do produto final da empresa. Também participei como chapter de frontend e iniciei um movimento de criação de ferramentas de suporte à tecnologia para nos dar melhor visibilidade e observabilidade das aplicações. A ferramenta foi carinhosamente chamada de "Dex".',
      },
      {
        title: 'Frontend Engineer',
        where: 'ACT Digital',
        from: '3/2020',
        to: '4/2022',
        description:
          'Na ACT tive a oportunidade de atuar em dois projetos interessantíssimos, um da Embraer que consistia em um motor de análise de risco de venda de aeronaves para os clientes da empresa. Essa ferramenta foi desenvolvida integrada ao CRM da empresa que era fornecido pela Salesforce. Também participei em um projeto do Banco BMG onde desenvolvemos o primeiro produto exclusivo para pessoa jurídica do banco sendo responsável por ajudar ainda na escolha e definição de padrões e arquitetura inicial para as aplicações do banco baseado em Micro frontend. As principais tecnologias utilizadas foram Typescript com React. Criamos uma biblioteca de componentes em React, implementando a nova imagem da marca com testes unitários, storybook e continuos deployment.',
      },

      {
        title: 'Frontend Engineer',
        where: 'IBM Brasil',
        from: '12/2017',
        to: '8/2019',
        description:
          'Atendendo o cliente Santander, desenvolvemos um dashboard em Angular para configuração e parametrização dos sistemas antifraude do banco. Atuamos com testes unitários que inicialmente rodavam sobre o PhantomJS usando Karma e posteriormente migrando para e Jest após a esteira de testes não mais suportar avaliar nossa aplicação. Atuamos no modelo ágil com ênfase no Scrum e tive minha primeira atuação com entrega contínua e integração.',
      },

      {
        title: 'Fullstack Developer',
        where: 'Catho Online',
        from: '2/2016',
        to: '11/2016',
        description:
          'Participei do remake do Guia de Profissões da Catho. Refizemos do zero o código front end do Guia de profissões, além de diversas melhorias no backend da aplicação, separando completamente as responsabilidades o que trouxe um belo ganho de performance. O Guia de profissões foi um dos primeiros projetos a inserir novas tecnologias na stack da Catho, meu primeiro contato com React e também um dos responsáveis por me ajudar a redirecionar minha carreira para frontend exclusivamente e me trouxe contato com o universo ágil tanto com Kanban quanto Scrum.',
      },
      {
        title: 'Fullstack Developer',
        where: 'Novosis',
        from: '6/2012',
        to: '7/2013',
        description: `Atuei como analista e programador júnior na empresa com a responsabilidade de criar uma estrutura para sustentação dos portais de transparência dos clientes da Novosis. Adquiri experiência em levantamento de requisitos e modelagem de sistemas e banco de dados e ajudei a criar os primeiros portais de transparência das prefeituras e câmaras da região. As principais tecnologias utilizadas foram PHP + MYSQL, Javascript, jQuery, CSS, HTML.`,
      },
    ];

    const formatExperienceItem = (item: InformationItemType): string => {
      const titleLine = `${item.title} @ ${item.where} - ${item.from} - ${item.to}\n`;
      return `${titleLine}\n${item.description}\n`;
    };
    const educationString =
      '-'.repeat(51).toString() +
      '\n' +
      myExperiences
        .map(formatExperienceItem)
        .join('\n' + '-'.repeat(51).toString()) +
      '-'.repeat(51).toString() +
      '\n';

    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      {
        type: LineType.Output,
        value:
          'Exibindo uma lista em ordem decrescente das minhas experiências profissionais.',
      },
      { type: LineType.Output, value: educationString },
      { type: LineType.Output, value: '\n\n' },
    ]);
  };

  const helpText = (input: string) =>
    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: '' },
      {
        type: LineType.Output,
        value: 'Exibe uma lista de comandos disponíveis',
      },
      {
        type: LineType.Output,
        value: 'Para o vagbash temos disponível os comandos abaixo',
      },
      { type: LineType.Output, value: '' },

      { type: LineType.Output, value: '- help : exibe essa janela de ajuda' },
      { type: LineType.Output, value: '- clear : limpa o terminal' },
      {
        type: LineType.Output,
        value: '- education : exibe uma lista de cursos e especializações',
      },
      {
        type: LineType.Output,
        value:
          '- skills : exibe uma lista de habilidades e conhecimentos específicos',
      },
      {
        type: LineType.Output,
        value: '- experiences : exibe uma lista de experiências e trabalhos',
      },
      {
        type: LineType.Output,
        value: '- hobbies : exibe uma lista de atividades extra curriculares',
      },
      { type: LineType.Output, value: '- salary : exibe o salário desejado' },
      {
        type: LineType.Output,
        value: '- whoami : exibe um texto descritivo sobre o usuário',
      },
      { type: LineType.Output, value: '' },
    ]);

  const whoami = (input: string) => {
    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: '' },
      {
        type: LineType.Output,
        value:
          'Quem é você (who am i) eu ainda não sei, mas posso falar um pouquinho de mim.',
      },
      { type: LineType.Output, value: '' },

      {
        type: LineType.Output,
        value: `- Tenho ${new Date().getFullYear() - 1987} anos`,
      },
      { type: LineType.Output, value: '- Nasci em Tupã - SP.' },
      {
        type: LineType.Output,
        value:
          '- Tenho quase certeza que os portões do inferno estão alí por perto porque, oh lugarzinho quente. 🔥',
      },
      {
        type: LineType.Output,
        value: '- Gosto do contato com a água (🌊, 🏊🏽, 🛁, 🌧)',
      },
      {
        type: LineType.Output,
        value:
          '- Toco violão, bem mal, mas toco. (Ah que burro, dá zero pra ele!)',
      },
      {
        type: LineType.Output,
        value: `- Trabalho com tecnologia desde 2008 - ${
          new Date().getFullYear() - 2008
        } anos`,
      },
      {
        type: LineType.Output,
        value: '- Já fui fullstack. PHP era um grande amigo. WordPress também',
      },
      {
        type: LineType.Output,
        value:
          '- Trabalho desde 2016 exclusivamente com frontend. Conheço Angular mas prefiro, advinha? Isso mesmo, React que usei pra construir esse terminal que você está acessando agora.',
      },
      {
        type: LineType.Output,
        value:
          '- Tenho conhecimentos em UX design e manjo um pouco de prototipagem com Figma',
      },
      {
        type: LineType.Output,
        value:
          '- Metodologias ágeis fazem parte do meu dia a dia, inclusive fora do trabalho. Scrum e Kanban são velhos conhecidos.',
      },
      { type: LineType.Output, value: '- My english is advanced! Yes, it is!' },
      {
        type: LineType.Output,
        value: '- Moro na beira da praia, sonho de infância',
      },
      { type: LineType.Output, value: '- Home office é primordial' },
      {
        type: LineType.Output,
        value: '- A meta é chegar a CTO e estou trabalhando duro nisso',
      },
      { type: LineType.Output, value: '' },
    ]);
  };

  const education = (input: string) => {
    const educationData: InformationItemType[] = [
      {
        title: 'Ciência da Computação',
        where: 'Universidade Anhembi Morumbi',
        from: '02/2021',
        to: 'present',
      },
      {
        title: 'Informática para internet',
        where: 'ETEC Tupã Prof. Massuyuki Kawano',
        from: '02/2011',
        to: '06/2012',
      },
      {
        title: 'Ensino médio & Magistério',
        where: 'CEFAM Prof. Odinir Magnani',
        from: '02/2003',
        to: '12/2005',
      },
    ];

    const formatEducationItem = (item: InformationItemType): string => {
      const courseLine = `Curso       | ${item.title}`;
      const institutionLine = `Instituição | ${item.where}`;
      const periodLine = `Período     | ${item.from} - ${item.to}`;
      return `${courseLine}\n${institutionLine}\n${periodLine}\n`;
    };
    const educationString =
      '-'.repeat(51).toString() +
      '\n' +
      educationData.map(formatEducationItem).join('\n\n') +
      '-'.repeat(51).toString() +
      '\n\n';

    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: '' },
      { type: LineType.Output, value: 'Eu estudei bastante\n' },
      {
        type: LineType.Output,
        value: 'Principalmente em casa. YouTube e Udemy, muito obrigado\n\n',
      },
      { type: LineType.Output, value: educationString },
    ]);
  };

  const hobbies = (input: string) => {
    const myHobbies = [
      'Fazer nada olhando o mar',
      'Violar tocão, ops, tocar violão',
      'Trilhas',
      'Séries de ficção científica',
    ];

    const myHobbiesString = myHobbies.join('\n');
    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: '' },
      {
        type: LineType.Output,
        value: 'Porque nem só de código se vive o homem.',
      },
      { type: LineType.Output, value: 'Um belo vinho sempre vai bem 🍷.' },
      {
        type: LineType.Output,
        value: myHobbiesString,
      },
      { type: LineType.Output, value: '' },
    ]);
  };

  const skills = (input: string) => {
    const items = [
      'Angular',
      'Azure Devops',
      'AWS Cloudfront',
      'AWS Elastic Beanstalk',
      'AWS Route 53',
      'AWS S3',
      'AWS Lambda',
      'Consume APIS',
      'Create APIS',
      'CSS',
      'Figma',
      'HTML',
      'Jest',
      'Linux',
      'PHP',
      'React JS',
      'Storybook',
      'Typescript',
      'UX Design',
      'Unit Tests',
    ];

    const itemsString = ' - ' + items.join('\n - ');

    setTerminalLineData([
      ...terminalLineData,
      { type: LineType.Input, value: input },
      { type: LineType.Output, value: '' },
      {
        type: LineType.Output,
        value:
          'Tenho domínio de intermediário à avançado\n nas seguintes tecnologias e serviços\n\n',
      },
      {
        type: LineType.Output,
        value: itemsString,
      },
      {
        type: LineType.Output,
        value: '\nAlgumas certificações estão a caminho.\n',
      },
      { type: LineType.Output, value: '' },
    ]);
  };

  const handleOnInput = (userInput: string | null | undefined) => {
    if (userInput === null || userInput === undefined) {
      return;
    }

    const input = userInput.trim();

    ReactGA.event({
      category: 'Terminal',
      action: 'Command',
      label: input,
    });

    switch (input) {
      case 'help':
        helpText(input);
        break;
      case 'skills':
        skills(input);
        break;
      case 'experiences':
        experiences(input);
        break;
      case 'education':
        education(input);
        break;
      case 'hobbies':
        hobbies(input);
        break;
      case 'whoami':
        whoami(input);
        break;
      case 'salary':
        salary(input);
        break;
      case 'contact':
        contact(input);
        break;
      case 'clear':
        setTerminalLineData([]);
        break;
      default:
        defaultResponse(input);
        break;
    }
  };

  return (
    <Container>
      <Terminal
        prompt="vagbash$"
        name="anonymous@terminal-vagner-leite-silva"
        colorMode={ColorMode.Dark}
        lineData={terminalLineData}
        onInput={handleOnInput}
      />
    </Container>
  );
};

export default TerminalApp;
