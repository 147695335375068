import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import GlobalStyles from './components/GlobalStyles';
import TerminalApp from './components/Terminal';

const TRACKING_ID = 'G-CLS1HC4LGK';

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({
      hitType: 'pageview',
      page: '/',
      title: 'vagbash',
    });
  }, []);

  return (
    <Router>
      <>
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<TerminalApp />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
